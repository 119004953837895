.container {
    max-width: 1440px;
    margin: 0 auto;
}

.header,
.content {
    padding: 0 11rem;
    width: 100%;
    box-sizing: border-box;
}

.headerWrapper {
    margin-top: 4rem;
    border-bottom: 0.0156rem solid var(--charcoal-500);
}

.search {
    font-size: 2.4375rem;
    font-weight: 600;
    color: #37353C;
}

.content {
    margin: 2.5625rem auto 5.8125rem auto;
}
.searchInputBar {
    display: flex;
    width: 100%;
    position: relative;
    margin: var(--lg) 0;
}
.searchIcon {
    margin-left: var(--xs);
    width: 1.125rem;
    height: 1.125rem;
}
.searchButton {
    position: absolute;
    right: 0;
}

.searchInput {
    border-radius: var(--md, 1.5rem);
    border: 0.0625rem solid var(--primary-100, #d3e3f1);
    background: var(--primary-50, #f4f8fc);
    height: 1.75rem;
    width: 100%;
    padding-left: .9375rem;
    padding-right: 6.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.searchItem {
    display: flex;
    flex-direction: column;
    gap: 0.5625rem;
    padding: 1.3125rem 0;
    border-bottom: 0.0313rem solid var(--charcoal-300);
}

.title {
    color: var(--brand-primary-500);
    font-weight: 500;
    margin: 0;
    line-height: 1.25rem;
    cursor: pointer;
}

.result {
    font-size: 1.5625rem;
    margin: 0;
    color: #37353C;
}

.subTitle {
    color: var(--charcoal-500);
    font-weight: 600;
    margin: 0;
    line-height: 1.75rem;
}

.description {
    font-size: 0.8125rem;
    margin: 0;
    line-height: 1.25rem;
}

.searchButtonMobile {
    display: none;
}

.description p {
    margin-bottom: 0;
}

@media screen and (max-width: 1024px) {
    .header,
    .content {
        padding: 0;
    }
    .searchButtonMobile {
        position: absolute;
        right: 0;
        display: none;
        display: block;
    }

    .search {
        font-size: 1.5rem;
        font-weight: 400;
        margin: 1.25rem var(--md);
    }
    .headerWrapper {
        margin-top: 0;
        border: 0;
    }

    .searchInputBar {
        margin: 0 var(--base);
        width: auto;
    }

    .searchButton {
        display: none;
    }
    .searchInput {
        height: 2.625rem;
        padding-left: var(--base);
        margin-right: var(--base);
    }
    .result {
        margin: var(--gap-12xl) var(--md) 0 var(--md);
        font-size: 1rem;
    }
    .content {
        margin-top: 0;
    }
    .title,
    .description,
    .subTitle {
        margin: 0 var(--md);
    }
}
